export const VISUALIZER = '[Visualizer]';
export const CONFIGURATOR = '[Configurator]';
export const CUSTOMER_RATINGS = '[Customer Ratings]';
export const PRODUCT_OVERVIEW = '[Product Overview]';
export const SPECIFICATIONS = '[Specifications]';
export const QUESTIONS_AND_ANSWERS = '[Questions & Answers]';
export const CUSTOMER_REVIEWS = '[Customer Reviews]';
// fulfillMent Method
export const FULFILLMENT_METHOD_APPLIANCE = 'DirectDelivery';
export const FULFILLMENT_METHOD_BOSS = 'ShipToStore';
export const FULFILLMENT_METHOD_BOPIS = 'BOPIS';
// LifeCycleEvent Name

export const LIFE_CYCLE_EVENT_CONFIGURATOR_DATA = "configurator.configurator_data";
export const LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING = "configurator.loading";
export const LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE = "configurator.configurator_unavailable";